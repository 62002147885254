<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Time Analysis</h1>
        </v-col>
        <v-col class="pl-12">
          <v-select
            label="Filter by Team Member"
            v-on:change="loadMemberTime"
            :items="teamMembers"
            v-model="fields.user_id"
            item-text="full_name"
            item-value="user.id"
            :return-object="false"
            :clearable="true"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table v-if="fields.user_id != null">
            <template v-slot:default v-if="this.loading">
              <tbody>
                <tr>
                  <td><h4>Time Logged Today</h4></td>
                  <td>
                    {{ userOverview.today }}
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="green lighten-4 green--text"
                          v-on="on"
                          @click="
                            $refs.tableBreakdown.open(
                              timeLoggedToday,
                              '',
                              userOverview.today
                            )
                          "
                        >
                          <v-icon x-small>mdi-alarm-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Time Logged Today</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr>
                  <td><h4>Time Logged Yesterday</h4></td>
                  <td>
                    {{ userOverview.yesterday }}
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          x-small
                          depressed
                          color="green lighten-4 green--text"
                          v-on="on"
                          @click="
                            $refs.tableBreakdown.open(
                              timeLoggedYesterday,
                              '',
                              userOverview.yesterday
                            )
                          "
                        >
                          <v-icon x-small>mdi-alarm-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Time Logged Yesterday</span>
                    </v-tooltip>
                  </td>
                </tr>
                <tr>
                  <td><h4>Time Logged This Week</h4></td>
                  <td>
                    {{ userOverview.this_week }}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td><h4>Time Logged This Month</h4></td>
                  <td>
                    {{ userOverview.this_month }}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td><h4>Time Logged Last Month</h4></td>
                  <td>
                    {{ userOverview.last_month }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>

    <table-breakdown ref="tableBreakdown" />
  </div>
</template>

<script>
import TableBreakdown from "/src/views/widgets/components/Table.vue";

export default {
  components: {
    TableBreakdown,
  },

  data() {
    return {
      fields: {
        user_id: null,
      },
      searchMember: null,
      userOverview: [],
      timeLoggedToday: [],
      timeLoggedYesterday: [],
      loading: false,
      breadcrumbs: [
        {
          text: "Workload",
          disabled: true,
        },
        {
          text: "Time Analysis",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },
  },

  methods: {
    loadMemberTime: function () {
      const appId = this.$route.params.id;
      const userId = this.fields.user_id;

      this.loading = false;
      this.$store
        .dispatch("tasking/recurringStore/loadMemberTime", {
          appId,
          userId,
        })
        .then((res) => {
          this.userOverview = res.data.userOverview;
          this.timeLoggedToday = res.data.timeLoggedToday;
          this.timeLoggedYesterday = res.data.timeLoggedYesterday;
          this.loading = true;
        })
        .catch(() => {});
    },
  },
};
</script>
