var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Time Analysis")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-select',{attrs:{"label":"Filter by Team Member","items":_vm.teamMembers,"item-text":"full_name","item-value":"user.id","return-object":false,"clearable":true,"outlined":"","dense":""},on:{"change":_vm.loadMemberTime},model:{value:(_vm.fields.user_id),callback:function ($$v) {_vm.$set(_vm.fields, "user_id", $$v)},expression:"fields.user_id"}})],1)],1),_c('v-row',[_c('v-col',[(_vm.fields.user_id != null)?_c('v-simple-table',{scopedSlots:_vm._u([(this.loading)?{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_c('h4',[_vm._v("Time Logged Today")])]),_c('td',[_vm._v(" "+_vm._s(_vm.userOverview.today)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text"},on:{"click":function($event){return _vm.$refs.tableBreakdown.open(
                            _vm.timeLoggedToday,
                            '',
                            _vm.userOverview.today
                          )}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-alarm-plus")])],1)]}}],null,false,955361158)},[_c('span',[_vm._v("Time Logged Today")])])],1)]),_c('tr',[_c('td',[_c('h4',[_vm._v("Time Logged Yesterday")])]),_c('td',[_vm._v(" "+_vm._s(_vm.userOverview.yesterday)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text"},on:{"click":function($event){return _vm.$refs.tableBreakdown.open(
                            _vm.timeLoggedYesterday,
                            '',
                            _vm.userOverview.yesterday
                          )}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-alarm-plus")])],1)]}}],null,false,898470470)},[_c('span',[_vm._v("Time Logged Yesterday")])])],1)]),_c('tr',[_c('td',[_c('h4',[_vm._v("Time Logged This Week")])]),_c('td',[_vm._v(" "+_vm._s(_vm.userOverview.this_week)+" ")]),_c('td')]),_c('tr',[_c('td',[_c('h4',[_vm._v("Time Logged This Month")])]),_c('td',[_vm._v(" "+_vm._s(_vm.userOverview.this_month)+" ")]),_c('td')]),_c('tr',[_c('td',[_c('h4',[_vm._v("Time Logged Last Month")])]),_c('td',[_vm._v(" "+_vm._s(_vm.userOverview.last_month)+" ")]),_c('td')])])]},proxy:true}:null],null,true)}):_vm._e()],1)],1)],1),_c('table-breakdown',{ref:"tableBreakdown"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }